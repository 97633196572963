app-root {
  .group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1em;

    &-header {
      width: 100%;

      h3,
      label {
        border-bottom: 1px solid #eee;
        text-align: left;
      }
    }

    &-item {
      padding-left: 1em;
      margin-bottom: 2em;
      align-self: flex-end;

      label {
        display: block;
      }
    }
  }
}
