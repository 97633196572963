.card {
    padding: 0 14px 15px;
}
.company-promo-module .card {
    padding-top: 2px;
}
.card .card-inner {
    border: 1px solid #dbdcdd;
    height: 100%;
}
.card .card-inner h3,
.card .card-inner p {
    margin-left: 20px;
    margin-right: 20px;
}
.card .card-inner h3 {
    padding-top: 10px;
    margin-bottom: 10px;
}
.card .card-content {
    margin-bottom: 32px;
}
.card .card-content p {
    font-size: 0.88em;
    line-height: 1.4em;
}
.card .card-bottom {
    position: absolute;
    bottom: 24px;
    left: 16px;
    /*width: 50%;*/
}
.card .card-bottom.centered {
    left: 0;
    width: 100%;
}
.card .card-top {
    margin-top: 14px;
    margin-bottom: 0;
}
.card .card-bottom.right {
    left: auto;
    right: 20px;
    bottom: 23px;
    text-align: right;
}
.card .card-bottom p {
    margin: auto;
}
.card .card-bottom.right p {
    display: inline;
}
.card .card-inner img {
    width: 100%;
}
.card .card-inner .category {
    font-size: 0.75em;
    color: #0066b2;
    font-family: "GothamBold", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 0;
}

.company-promo-module .card .card-inner h3 {
    margin-bottom: 70px;
}

/* Small, Med, and Large devices (tablets, 768px and up) */
@media (min-width: 991px) {
    .card {
        min-height: 340px;
    }
    .op-company-promo-module .card {
        min-height: 220px;
    }
}
@media (min-width: 1200px) {
    .card .card-bottom.dynamic-bottom .col {
        height: 32px;
    }
    .card .card-bottom.dynamic-bottom .col p.bottom {
        margin-top: 0;
    }
    .card .card-bottom.dynamic-bottom .col.pull-left {
        padding: 0 5px 0 20px;
    }
    .card .card-bottom.dynamic-bottom .col.pull-right {
        width: 95px;
    }
}

@media (max-width: 1199px) {
    .card .card-bottom.dynamic-bottom .col {
        width: 100%;
    }
    .card .card-bottom.dynamic-bottom .col p.bottom {
        text-align: center !important;
    }
    .card .card-bottom.dynamic-bottom .col.pull-left {
        padding: 0 5px;
    }
}

/* print styles */
@media print {
    .card {
        page-break-inside: avoid;
        height: auto !important;
    }
}
